import {
  ChatBubbleOutline,
  CloseRounded,
  Email,
  FilterAlt,
  WhatsApp,
} from '@mui/icons-material';
import {
  Divider,
  IconButton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import logo from '../../media/logo-gjb-nobg.png';
import { toast } from 'react-toastify';

const FullCompanyCard = ({ handleCloseFullProfessionalCardDialog }) => {
  const { selectedCompany } = useSelector((state) => state.dialogValues);

  const handleEmailClick = () => {
    if (!selectedCompany.userEmail) {
      toast.error('Email não cadastrado');
      return;
    }
    window.location.href = `mailto:${selectedCompany.userEmail}`;
  };

  const handleWhatsappClick = () => {
    if (!selectedCompany.companyPhone) {
      toast.error('Telefone não cadastrado');
      return;
    }

    window.open(
      `https://api.whatsapp.com/send?phone=${selectedCompany.companyPhone}`
    );
  };

  if (!selectedCompany) {
    return <div>Loading...</div>;
  }

  return (
    <div className='p-8'>
      <div className='flex justify-end'>
        <IconButton
          edge='end'
          color='success'
          variant='contained'
          onClick={handleCloseFullProfessionalCardDialog}
          aria-label='close'
        >
          <CloseRounded />
        </IconButton>
      </div>
      <div className=' flex flex-col gap-4'>
        <div className='flex flex-col gap-2 rounded-full my-4 border-2 border-green-700 w-fit h-fit m-auto sm:mx-0'>
          <img src={logo} alt='' width='200px' height='200px' />
        </div>
        <div className='flex flex-col gap-2'>
          <h1 className='text-xl md:text-3xl font-bold text-green-700'>
            {selectedCompany.companyName}
          </h1>
          <p className='text-md md:text-xl font-bold'>{`${selectedCompany.companyFieldOfActivity[0]}`}</p>
        </div>
        <Divider />

        <div className='flex flex-col gap-2'>
          <h1 className='text-lg md:text-2xl font-bold text-green-700'>
            Visão geral
          </h1>

          <p className='text-sm md:text-lg'>
            {selectedCompany.companyDescription}
          </p>
        </div>
        {selectedCompany.companyWebsite && (
          <div className='flex flex-col gap-2'>
            <h1 className='text-lg md:text-2xl font-bold text-green-700'>
              Site
            </h1>

            <p className='text-sm md:text-lg'>
              {selectedCompany.companyWebsite}
            </p>
          </div>
        )}
        <div className='flex flex-col gap-2'>
          <h1 className='text-lg md:text-2xl font-bold text-green-700'>
            Telefone
          </h1>

          <p className='text-sm md:text-lg'>{selectedCompany.companyPhone}</p>
        </div>
        <div className='flex flex-col gap-2'>
          <h1 className='text-lg md:text-2xl font-bold text-green-700'>
            Setor
          </h1>
          {selectedCompany.companyFieldOfActivity.map((skill, index) => (
            <p key={index} className='text-sm md:text-lg'>
              {skill}
            </p>
          ))}
        </div>
        <div className='flex flex-col gap-2'>
          <h1 className='text-lg md:text-2xl font-bold text-green-700'>Sede</h1>
          {`${selectedCompany.companyCity}, ${selectedCompany.companyState}`}
        </div>

        <div className='flex flex-col gap-2'>
          <h1 className='text-lg md:text-2xl font-bold text-green-700'>
            Informações adicionais
          </h1>

          <p className='text-sm md:text-lg'>
            {`Cadastrado em: ${new Date(
              selectedCompany.dateCreate
            ).toLocaleDateString()}`}
          </p>
        </div>
      </div>
      <SpeedDial
        ariaLabel='Contato'
        // sx={{ position: 'fixed', bottom: 40, right: 60, color: 'green' }}
        icon={<ChatBubbleOutline />}
        direction='up'
        FabProps={{ color: 'success' }}
        className='fixed bottom-4 right-4 md:bottom-12 md:right-14'
      >
        <SpeedDialAction
          key='whatsapp'
          icon={<WhatsApp />}
          tooltipTitle='Whatsapp'
          onClick={handleWhatsappClick}
        />
        <SpeedDialAction
          key='email'
          icon={<Email />}
          tooltipTitle='Email'
          onClick={handleEmailClick}
        />
      </SpeedDial>
    </div>
  );
};

export default FullCompanyCard;

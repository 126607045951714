import React from 'react';

import { Button, Dialog, DialogTitle } from '@mui/material';
import {
  graduationYear,
  professionalFormFields,
  states,
} from '../../helper/profileFormFields';

const ProfessionalsFiltersDialog = ({
  professionalsFiltersDialogOpen,
  handleFiltersDialogClose,
  handleSubmit,
  onSubmit,
  register,
}) => {
  return (
    <Dialog
      open={professionalsFiltersDialogOpen}
      onClose={handleFiltersDialogClose}
      fullWidth
    >
      <DialogTitle alignSelf={'center'} fontSize={'24px'} color={'green'}>
        Filtros
      </DialogTitle>
      <div className='flex flex-col gap-6 p-6 w-full items-center'>
        <div className='flex flex-col gap-2 w-full xs:w-3/5 sm:w-3/5 md:w-2/5'>
          <label className='text-green-700 font-medium'>Área de Atuação</label>
          <select
            {...register('fieldOfActivity')}
            id='fieldOfActivity'
            className='w-full h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-green-500'
          >
            <option value=''></option>
            {professionalFormFields.fieldOfActivity.map((activity) => (
              <option key={activity} value={activity}>
                {activity}
              </option>
            ))}
          </select>
        </div>
        <div className='flex flex-col gap-2 w-full xs:w-3/5 sm:w-3/5 md:w-2/5'>
          <label className='text-green-700 font-medium'>
            Qualificação Profissional
          </label>
          <select
            {...register('professionalQualification')}
            id='professionalQualification'
            className='w-full h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-green-500'
          >
            <option value=''></option>
            {professionalFormFields.professionalQualification.map(
              (qualification) => (
                <option key={qualification} value={qualification}>
                  {qualification}
                </option>
              )
            )}
          </select>
        </div>
        <div className='flex flex-col gap-2 w-full xs:w-3/5 sm:w-3/5 md:w-2/5'>
          <label className='w-32 text-green-700 font-medium'>Estado</label>
          <select
            {...register('state')}
            id='state'
            className='w-full h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-green-500'
          >
            <option value=''></option>
            {states.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>

        <div className='flex flex-col gap-2 w-full xs:w-3/5 sm:w-3/5 md:w-2/5'>
          <label className='text-green-700 font-medium'>Especialidade</label>
          <select
            {...register('specialty')}
            id='specialty'
            className='w-full h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-green-500'
          >
            <option value=''></option>
            {professionalFormFields.specialty.map((specialty) => (
              <option key={specialty} value={specialty}>
                {specialty}
              </option>
            ))}
          </select>
        </div>
        <div className='flex flex-col gap-2 w-full xs:w-3/5 sm:w-3/5 md:w-2/5'>
          <label className='text-green-700 font-medium'>Titulação</label>
          <select
            {...register('titration')}
            id='titration'
            className='w-full h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-green-500'
          >
            <option value=''></option>
            {professionalFormFields.titration.map((titration) => (
              <option key={titration} value={titration}>
                {titration}
              </option>
            ))}
          </select>
        </div>
        <div className='flex flex-col gap-2 w-full xs:w-3/5 sm:w-3/5 md:w-2/5'>
          <label className='text-green-700 font-medium'>CLT?</label>
          <select
            {...register('clt')}
            id='clt'
            className='w-full h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-green-500'
          >
            <option value=''></option>
            <option value='true'>Sim</option>
            <option value='false'>Não</option>
          </select>
        </div>
        <div className='flex flex-col gap-2 w-full xs:w-3/5 sm:w-3/5 md:w-2/5'>
          <label className='text-green-700 font-medium'>PJ?</label>
          <select
            {...register('pj')}
            id='pj'
            className='w-full h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-green-500'
          >
            <option value=''></option>
            <option value='true'>Sim</option>
            <option value='false'>Não</option>
          </select>
        </div>
        <div className='flex flex-col gap-2 w-full xs:w-3/5 sm:w-3/5 md:w-2/5'>
          <label className='text-green-700 font-medium'>Freelancer?</label>
          <select
            {...register('freelancer')}
            id='freelancer'
            className='w-full h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-green-500'
          >
            <option value=''></option>
            <option value='true'>Sim</option>
            <option value='false'>Não</option>
          </select>
        </div>
        <div className='flex flex-col gap-2 w-full xs:w-3/5 sm:w-3/5 md:w-2/5'>
          <label className='text-green-700 font-medium'>Possui ART?</label>
          <select
            {...register('art')}
            id='art'
            className='w-full h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-green-500'
          >
            <option value=''></option>
            <option value='true'>Sim</option>
            <option value='false'>Não</option>
          </select>
        </div>
        <div className='flex flex-col gap-2 w-full xs:w-3/5 sm:w-3/5 md:w-2/5'>
          <label className='text-green-700 font-medium'>Possui CNH?</label>
          <select
            {...register('driverLicense')}
            id='driverLicense'
            className='w-full h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-green-500'
          >
            <option value=''></option>
            <option value='true'>Sim</option>
            <option value='false'>Não</option>
          </select>
        </div>
        <div className='flex flex-col gap-2 w-full xs:w-3/5 sm:w-3/5 md:w-2/5'>
          <label className='text-green-700 font-medium'>Pode viajar?</label>
          <select
            {...register('canTravel')}
            id='canTravel'
            className='w-full h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-green-500'
          >
            <option value=''></option>
            <option value='true'>Sim</option>
            <option value='false'>Não</option>
          </select>
        </div>
        <div className='flex flex-col gap-2 w-full xs:w-3/5 sm:w-3/5 md:w-2/5'>
          <label className='text-green-700 font-medium'>Ano de Graduação</label>
          <select
            {...register('graduationYear')}
            id='graduationYear'
            className='w-full h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-green-500'
          >
            <option value=''></option>
            {graduationYear.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div className='p-4'>
          <Button
            variant='contained'
            color='success'
            onClick={handleSubmit(onSubmit)}
          >
            Buscar
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ProfessionalsFiltersDialog;

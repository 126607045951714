export const professionalFormFields = {
  professionalQualification: [
    'Biologia',
    'Engenharia Ambiental',
    'Agrônomia',
    'Geografia',
    'Engenharia Florestal',
    'Técnico em Meio Ambiente',
    'Geologia',
  ],
  specialty: [
    'Botânica',
    'Zoologia',
    'Limnologia',
    'Ecologia',
    'Mastofauna',
    'Herpetofauna',
    'Genética',
    'Biotecnologia',
    'Bioeconomia',
    'ESG',
    'Sustentabilidade',
    'Bioinformática',
  ],
  fieldOfActivity: [
    'Gestão Ambiental',
    'Técnico em Meio Ambiente',
    'Analista Ambiental - ESG - Sustentabilidade',
    'Consultor Ambiental - ESG - Sustentabilidade',
    'Auditor Ambiental',
    'Gestor de Resíduos',
    'Especialista em Energias Renováveis',
    'Coordenador de Projetos Socioambientais',
    'Especialista em Recursos Hídricos',
    'Perito Ambiental',
    'Educador Ambiental',
    'Técnico em Saneamento Ambiental',
    'Especialista em Biodiversidade',
    'Engenheiro Agrícola',
    'Analista de Impacto Ambiental',
    'Especialista em GIS (Sistemas de Informação Geográfica)',
    'Técnico em Geoprocessamento',
    'Engenheiro de Minas (com foco em sustentabilidade)',
    'Especialista em Mudanças Climáticas',
    'Técnico em Controle Ambiental',
    'Pesquisador em Ciências Ambientais',
    'Especialista em Economia Circular',
    'Engenheiro de Produção com foco em sustentabilidade',
    'Especialista em Educação Ambiental',
    'Coordenador de Projetos de Carbono',
    'Especialista em Tecnologia Ambiental',
    'Especialista em Saúde Ambiental',
    'Gestor de Áreas Protegidas',
    'Especialista em Poluição do Ar',
    'Consultor em Sustentabilidade Corporativa',
    'Especialista em Gestão de Riscos Ambientais',
    'Engenheiro de Pesca com foco em conservação',
    'Analista de Segurança Química',
    'Especialista em Controle de Emissões Atmosféricas',
    'Especialista em Restauração Ecológica',
    'Consultor em Energia Limpa',
    'Técnico em Controle de Qualidade Ambiental',
    'Especialista em Gestão de Águas Residuais',
    'Especialista em Recuperação de Áreas Degradadas',
    'Engenheiro de Ecossistemas',
    'Especialista em Desenvolvimento Sustentável',
    'Analista de Sustentabilidade Urbana',
    'Gestor de Projetos de Conservação da Natureza',
  ],
  titration: ['Mestrado', 'Doutorado', 'Pós-Doutorado'],
};

export const companyFormFields = {
  fieldOfActivity: [
    'Consultoria Ambiental',
    'Consultoria Florestal',
    'Gestão Ambiental',
    'Consultoria ESG',
    'Consultoria em Sustentabilidade',
  ],
  services: [
    'Estudos de viabilidade ambiental',
    'Diagnóstico ambiental ou auditoria ambiental',
    'Gestão de conflitos',
    'Consultoria ambiental estratégica',
    'Avaliação preliminar de áreas contaminadas',
    'Investigação confirmatória de áreas contaminadas',
    'Investigação detalhada de áreas contaminadas e análise de risco',
    'Projetos de remediação de áreas contaminadas',
    'Programa de gerenciamento de resíduos sólidos',
    'Implantação de reserva legal',
    'Inventário de emissões odoríferas (ou inventário de odores)',
    'Estimativa de emissões atmosféricas',
    'Modelagem matemática da dispersão atmosférica de poluentes',
    'Plano de monitoramento de emissões atmosféricas',
    'Inventário de emissões de GEE',
    'Inventário de florestal',
    'Gestão de ETE',
    'Gestão de Resíduos',
    'Inventário de Fauna',
    'Gestão de parques naturais',
    'Soluções sustentáveis',
    'Relatórios de sustentabilidade',
  ],
};

// full state name
export const states = [
  'Acre',
  'Alagoas',
  'Amapá',
  'Amazonas',
  'Bahia',
  'Ceará',
  'Distrito Federal',
  'Espírito Santo',
  'Goiás',
  'Maranhão',
  'Mato Grosso',
  'Mato Grosso do Sul',
  'Minas Gerais',
  'Pará',
  'Paraíba',
  'Paraná',
  'Pernambuco',
  'Piauí',
  'Rio de Janeiro',
  'Rio Grande do Norte',
  'Rio Grande do Sul',
  'Rondônia',
  'Roraima',
  'Santa Catarina',
  'São Paulo',
  'Sergipe',
  'Tocantins',
];

// years from 1970 to 2024
export const graduationYear = Array.from({ length: 55 }, (_, i) => 1970 + i);

import React from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import {
  graduationYear,
  professionalFormFields,
  states,
} from '../../helper/profileFormFields';

const EditProfessionalForm = ({
  handleSubmit,
  handleRegister,
  handleFileChange,
  register,
  watch,
  getValues,
}) => {
  return (
    <div className='w-full'>
      <form onSubmit={handleSubmit(handleRegister)}>
        <div className='flex flex-col items-center p-4 gap-12 mt-6 '>
          <div className='flex gap-4 flex-wrap justify-center'>
            <label
              className='block text-sm font-medium text-gray-900 dark:text-white'
              htmlFor='file_input'
            >
              Foto de Perfil
            </label>
            <input
              {...register('file_input', { required: true })}
              className='block w-full text-sm text-gray-900 border border-gray-300 rounded-md cursor-pointer bg-gray-50 focus:outline-none '
              aria-describedby='file_input_help'
              id='file_input'
              type='file'
              accept='image/*'
              onChange={handleFileChange}
            />
            <p
              className='text-sm text-gray-500 dark:text-gray-300'
              id='file_input_help'
            >
              PNG ou JPG.
            </p>
            {watch('companyLogo') && (
              <img
                src={URL.createObjectURL(getValues('companyLogo'))}
                alt='logo da empresa'
              />
            )}
          </div>
          <div className='w-5/6 gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
            <div className='col-span-1 md:col-span-2 lg:col-span-3'>
              <TextField
                fullWidth
                {...register('description', {
                  required: true,
                })}
                id='description-input'
                label='Sobre você'
                placeholder='Sobre você'
                type='text'
                multiline
                rows={5}
                variant='filled'
              />
            </div>
            <TextField
              {...register('phone', {
                required: true,
              })}
              id='phone-select'
              label='Whatsapp'
              placeholder='Whatsapp'
              type='text'
            />
            <TextField
              {...register('city', {
                required: true,
              })}
              id='city-select'
              label='Cidade'
              placeholder='Cidade'
              type='text'
            />
            <FormControl>
              <InputLabel id='state'>Estado</InputLabel>
              <Select
                {...register('state', { required: true })}
                labelId='state'
                id='state-select'
                defaultValue=''
                label='Estado'
                // onChange={handleProfileChange}
              >
                {states.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id='professionalQualification'>Formação</InputLabel>
              <Select
                {...register('professionalQualification', {
                  required: true,
                })}
                labelId='professionalQualification'
                id='professionalQualification-select'
                defaultValue=''
                label='Formação'
                // onChange={handleProfileChange}
              >
                {professionalFormFields.professionalQualification.map((i) => (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id='titration'>Titulação</InputLabel>
              <Select
                {...register('titration', {
                  required: true,
                })}
                labelId='titration'
                id='titration-select'
                defaultValue=''
                label='Formação'
                // onChange={handleProfileChange}
              >
                {professionalFormFields.titration.map((i) => (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id='specialty'>Especialidade</InputLabel>
              <Select
                {...register('specialty', {
                  required: true,
                })}
                labelId='specialty'
                id='specialty-select'
                defaultValue=''
                label='Formação'
                // onChange={handleProfileChange}
              >
                {professionalFormFields.specialty.map((i) => (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id='fieldOfActivity'>Área de Atuação</InputLabel>
              <Select
                {...register('fieldOfActivity', {
                  required: true,
                })}
                labelId='fieldOfActivity'
                id='fieldOfActivity-select'
                defaultValue=''
                label='Formação'
                // onChange={handleProfileChange}
              >
                {professionalFormFields.fieldOfActivity.map((i) => (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id='clt'>Aceita CLT?</InputLabel>
              <Select
                {...register('clt', { required: true })}
                labelId='clt'
                id='clt-select'
                defaultValue=''
                label='Aceita CLT?'
                // onChange={handleProfileChange}
              >
                <MenuItem value='true'>Sim</MenuItem>
                <MenuItem value='false'>Não</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id='pj'>Aceita PJ?</InputLabel>
              <Select
                {...register('pj', { required: true })}
                labelId='pj'
                id='pj-select'
                defaultValue=''
                label='Aceita PJ?'
                // onChange={handleProfileChange}
              >
                <MenuItem value='true'>Sim</MenuItem>
                <MenuItem value='false'>Não</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id='freelancer'>Aceita Freelancer?</InputLabel>
              <Select
                {...register('freelancer', { required: true })}
                labelId='freelancer'
                id='freelancer-select'
                defaultValue=''
                label='Aceita Freelancer?'
                // onChange={handleProfileChange}
              >
                <MenuItem value='true'>Sim</MenuItem>
                <MenuItem value='false'>Não</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id='art'>Possui ART?</InputLabel>
              <Select
                {...register('art', { required: true })}
                labelId='art'
                id='art-select'
                defaultValue=''
                label='Aceita art?'
                // onChange={handleProfileChange}
              >
                <MenuItem value='true'>Sim</MenuItem>
                <MenuItem value='false'>Não</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id='driverLicense'>Possui CNH?</InputLabel>
              <Select
                {...register('driverLicense', { required: true })}
                labelId='driverLicense'
                id='driverLicense-select'
                defaultValue=''
                label='Aceita driverLicense?'
                // onChange={handleProfileChange}
              >
                <MenuItem value='true'>Sim</MenuItem>
                <MenuItem value='false'>Não</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id='canTravel'>Pode viajar?</InputLabel>
              <Select
                {...register('canTravel', { required: true })}
                labelId='canTravel'
                id='canTravel-select'
                defaultValue=''
                label='Aceita canTravel?'
                // onChange={handleProfileChange}
              >
                <MenuItem value='true'>Sim</MenuItem>
                <MenuItem value='false'>Não</MenuItem>
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id='graduationYear'>Ano de Formação</InputLabel>
              <Select
                {...register('graduationYear', {
                  required: true,
                })}
                labelId='graduationYear'
                id='graduationYear-select'
                defaultValue=''
                label='Ano de Formação'
                // onChange={handleProfileChange}
              >
                {graduationYear.map((i) => (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Button
            className=''
            variant='contained'
            color='success'
            size='large'
            type='submit'
          >
            Finalizar Perfil
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditProfessionalForm;

import React from 'react';
import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import {
  graduationYear,
  professionalFormFields,
  states,
} from '../../helper/profileFormFields';
import { industryList } from '../../helper/industry';
import { useSelector } from 'react-redux';
import { get } from 'react-hook-form';
import { Add } from '@mui/icons-material';
import { toast } from 'react-toastify';

const EditCompanyForm = ({
  handleSubmit,
  handleRegister,
  handleFileChange,
  register,
  watch,
  getValues,
  setValue,
}) => {
  const { userData } = useSelector((state) => state.loginValues);

  return (
    <div className='w-full'>
      <form onSubmit={handleSubmit(handleRegister)}>
        <div className='flex flex-col items-center p-4 gap-12 mt-6 '>
          <div className='flex gap-4 flex-wrap justify-center'>
            <label
              className='block text-sm font-medium text-gray-900 dark:text-white'
              htmlFor='file_input'
            >
              Logo da Empresa
            </label>
            <input
              {...register('file_input', { required: true })}
              className='block w-full text-sm text-gray-900 border border-gray-300 rounded-md cursor-pointer bg-gray-50 focus:outline-none '
              aria-describedby='file_input_help'
              id='file_input'
              type='file'
              accept='image/*'
              onChange={handleFileChange}
            />
            <p
              className='text-sm text-gray-500 dark:text-gray-300'
              id='file_input_help'
            >
              PNG ou JPG.
            </p>
          </div>
          {watch('companyLogo') && (
            <div className='rounded-lg border border-black w-48 h-48 shadow-xl'>
              <img
                src={URL.createObjectURL(getValues('companyLogo'))}
                alt='logo da empresa'
                className='w-full h-full object-cover rounded-lg p-1  border border-white'
              />
            </div>
          )}
          <div className='w-5/6 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
            <div className='col-span-1 md:col-span-2 lg:col-span-3'>
              <TextField
                fullWidth
                {...register('companyDescription', {
                  required: true,
                })}
                id='description-input'
                label='Visão Geral'
                placeholder='Visão Geral'
                type='text'
                multiline
                rows={5}
                variant='filled'
                // defaultValue={userData.companyDescription}
              />
            </div>
            <TextField
              {...register('companyName', {
                required: true,
              })}
              id='companyName-select'
              label='Nome da Empresa'
              placeholder='Nome da Empresa'
              type='text'
              // defaultValue={userData.companyName}
            />
            <TextField
              {...register('companyPhone', {
                required: true,
              })}
              id='phone-select'
              label='Telefone'
              placeholder='Telefone (opcional)'
              type='text'
              // defaultValue={userData.companyPhone}
            />
            <TextField
              {...register('companyWebsite', {
                required: true,
              })}
              id='website-input'
              label='Site'
              placeholder='Ex.: https://minhaempresa.com.br'
              type='text'
              // defaultValue={userData.companyWebsite}
              onChange={(e) => {
                console.log(e.target.value);
              }}
            />
            <TextField
              {...register('companyCity', {
                required: true,
              })}
              id='city-select'
              label='Cidade'
              placeholder='Cidade'
              type='text'
              // defaultValue={userData.companyCity}
            />
            <FormControl>
              <InputLabel id='state'>Estado</InputLabel>
              <Select
                {...register('companyState', { required: true })}
                labelId='state'
                id='state-select'
                label='Estado'
                defaultValue={userData.companyState}
              >
                {states.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              {/* <InputLabel id='companyFieldOfActivity'>Setor</InputLabel> */}
              <Autocomplete
                multiple
                id='tags-outlined'
                options={industryList}
                filterSelectedOptions
                clearText='Limpar'
                noOptionsText='Sem opções'
                closeText='Fechar'
                defaultValue={userData.companyFieldOfActivity}
                onChange={(event, value) => {
                  console.log('value', value);
                  setValue('companyFieldOfActivity', value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label='Setor' placeholder='' />
                )}
              />
            </FormControl>
            <div className='flex flex-col gap-6'>
              <div className='flex gap-4'>
                <TextField
                  {...register('tempCompanySpecialty', {
                    // required: true,
                  })}
                  fullWidth
                  id='companySpecialty-input'
                  label='Especialidade'
                  placeholder='Max. 5 especialidades'
                  type='text'
                  // defaultValue={userData.companyPhone}
                />
              </div>
              {watch('tempCompanySpecialty') && (
                <Button
                  onClick={() => {
                    if (getValues('companySpecialty').length < 5) {
                      setValue('companySpecialty', [
                        ...getValues('companySpecialty'),
                        getValues('tempCompanySpecialty'),
                      ]);
                      setValue('tempCompanySpecialty', '');
                    } else {
                      toast.error('Máximo de 5 especialidades');
                    }
                  }}
                  color='success'
                  variant='contained'
                  endIcon={<Add />}
                >
                  Adicionar
                </Button>
              )}
              {console.log(getValues('companySpecialty'))}
              {watch('companySpecialty') &&
                getValues('companySpecialty').map((specialty, index) => (
                  <Chip
                    label={specialty}
                    // Remove specialty from list
                    onDelete={() => {
                      const filteredSpecialties = getValues(
                        'companySpecialty'
                      ).filter((item) => item !== specialty);
                      setValue('companySpecialty', filteredSpecialties);
                    }}
                  />
                ))}
            </div>
          </div>
          <div className='flex gap-10'>
            <Button
              className=''
              variant='contained'
              color='success'
              size='large'
              type='submit'
            >
              Salvar
            </Button>

            <Button
              className=''
              variant='contained'
              color='error'
              size='large'
              onClick={() => window.history.back()}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditCompanyForm;

import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { auth } from '../../firebase-config';
import { useForm } from 'react-hook-form';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { toast } from 'react-toastify';
import {
  createInitialCompany,
  createInitialProfessional,
} from '../../services/userServices';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginValuesActions } from '../../store/loginValues';

const RegisterForm = ({ setLogin }) => {
  const [profile, setProfile] = useState('company');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleProfileChange = (event) => {
    setProfile(event.target.value);
  };

  const handleRegister = ({ email, password, name, lastname, profile }) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log('User is signed in', user);
        updateProfile(user, {
          displayName: `${name} ${lastname}`,
        }).then(() => {
          try {
            dispatch(loginValuesActions.setUserName(`${name} ${lastname}`));
            if (profile === 'company') {
              createInitialCompany({
                userId: user.uid,
                userEmail: user.email,
                name: name,
                lastName: lastname,
                profile: profile,
              }).then((company) => {
                dispatch(loginValuesActions.setUserData(company));
                toast.success('Conta criada com sucesso!');
                navigate('/completar-perfil');
              });
            } else {
              createInitialProfessional({
                userId: user.uid,
                userEmail: user.email,
                name: name,
                lastName: lastname,
                profile: profile,
              }).then((professional) => {
                dispatch(loginValuesActions.setUserData(professional.json()));
                toast.success('Conta criada com sucesso!');
                navigate('/completar-perfil');
              });
            }
          } catch (error) {
            console.error('Error:', error);
            // delete the firebase user
            user.delete().then(() => {
              toast.error('Erro ao criar conta');
            });
          }
        });
      })
      // ...

      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('Error: ', errorCode, errorMessage);
      });
  };

  const {
    formState,
    register,

    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    resetField,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      name: '',
      lastname: '',
      profile: 'company',
    },
  });

  return (
    <div>
      <form onSubmit={handleSubmit(handleRegister)}>
        <div className='flex flex-col p-4 gap-8 mt-6'>
          <div className='flex flex-col gap-4 flex-wrap'>
            <Typography variant='h6' fontSize={16} fontWeight='bold'>
              Crie sua conta
            </Typography>
            <TextField
              {...register('email', { required: true })}
              id='email-button'
              label='Email'
              placeholder='Digite seu email'
              type='email'
            />
            <TextField
              {...register('password', { required: true })}
              id='password-button'
              label='Senha'
              placeholder='Digite sua senha'
              type='password'
            />
            <TextField
              {...register('name', { required: true })}
              id='name-button'
              label='Nome'
              placeholder='Digite seu nome'
              type='text'
            />
            <TextField
              {...register('lastname', { required: true })}
              id='lastname-button'
              label='Sobrenome'
              placeholder='Digite seu sobrenome'
              type='text'
            />
            <FormControl fullWidth>
              <InputLabel id='profile'>Perfil</InputLabel>
              <Select
                {...register('profile', { required: true })}
                labelId='profile'
                id='profile-select'
                value={profile}
                label='Perfil'
                onChange={handleProfileChange}
              >
                <MenuItem value='company'>Empresa</MenuItem>
                <MenuItem value='professional'>Profissional</MenuItem>
              </Select>
            </FormControl>
            <p
              className='hover:text-emerald-800 hover:underline hover:cursor-pointer w-fit'
              onClick={() => setLogin(true)}
            >
              Já possui conta? Faça login
            </p>
          </div>
          <Button
            className=''
            variant='contained'
            color='success'
            size='large'
            type='submit'
          >
            Criar Conta
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RegisterForm;

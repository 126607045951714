import React, { useEffect, useState } from 'react';

import { auth } from '../firebase-config';
import ProfessionalsNotLogged from '../components/Layout/ProfessionalsNotLogged';
import { onAuthStateChanged } from 'firebase/auth';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Slide,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  graduationYear,
  professionalFormFields,
  states,
} from '../helper/profileFormFields';
import { useForm } from 'react-hook-form';
import { getProfessionals } from '../services/professionalServices';
import { CloseOutlined, FilterAlt, TuneRounded } from '@mui/icons-material';
import ProfessionalCard from '../components/Cards/ProfessionalCard';
import ProfessionalsFiltersDialog from '../components/Dialogs/ProfessionalsFiltersDialog';
import FullProfessionalCardDialog from '../components/Dialogs/FullProfessionalCardDialog';
import { useDispatch, useSelector } from 'react-redux';
import { dialogValuesActions } from '../store/dialogValues';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ProfessionalsPage = () => {
  const dispatch = useDispatch();
  const { selectedProfessional } = useSelector((state) => state.dialogValues);

  const [user, setUser] = useState(null);
  const [professionalsFiltersDialogOpen, setProfessionalsFiltersDialogOpen] =
    useState(false);
  const [filteredResults, setFilteredResults] = useState([]);
  const [professionalDialogOpen, setProfessionalDialogOpen] = useState(false);

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm();

  const handleFiltersDialogClose = () => {
    setProfessionalsFiltersDialogOpen(false);
  };

  const handleOpenFullProfessionalCardDialog = (results) => {
    dispatch(dialogValuesActions.setSelectedProfessional(results));
    setProfessionalDialogOpen(true);
  };

  const handleCloseFullProfessionalCardDialog = () => {
    dispatch(dialogValuesActions.resetSelectedProfessional());
    setProfessionalDialogOpen(false);
  };

  const onSubmit = (data) => {
    console.log(data);
    getProfessionals(data).then((data) => {
      console.log(data);
      setFilteredResults(data);
    });
    setProfessionalsFiltersDialogOpen(false);
  };

  // check if the user is logged in. If its not, render the ProfessionalsNotLogged component
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        // console.log('User is not logged in');
        setUser(null);
      }
    });
    return unsubscribe;
  }, []);

  return (
    // render the ProfessionalsNotLogged component if the user is not logged in
    user === null ? (
      <ProfessionalsNotLogged />
    ) : (
      <div className='flex flex-col items-center gap-6 my-8'>
        <ProfessionalsFiltersDialog
          professionalsFiltersDialogOpen={professionalsFiltersDialogOpen}
          handleFiltersDialogClose={handleFiltersDialogClose}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          register={register}
        />

        {selectedProfessional && (
          <FullProfessionalCardDialog
            handleCloseFullProfessionalCardDialog={
              handleCloseFullProfessionalCardDialog
            }
            professionalDialogOpen={professionalDialogOpen}
          />
        )}

        {filteredResults.length == 0 && (
          <div className='mt-20 text-center'>
            <h1 className='text-xl md:text-3xl'>
              Nenhum resultado para exibir
            </h1>
            <h1 className='text-xl md:text-3xl mt-4'>
              {`Clique no botão `}
              <TuneRounded color='success' />
              {` para filtrar os resultados`}
            </h1>
          </div>
        )}
        {filteredResults.map((results) => (
          <ProfessionalCard
            results={results}
            handleOpenFullProfessionalCardDialog={
              handleOpenFullProfessionalCardDialog
            }
            key={results._id}
          />
        ))}
        <div className='fixed bottom-6 right-6'>
          <Tooltip title='Filtros' placement='left' arrow enterDelay={100}>
            <Button
              variant='contained'
              color='success'
              sx={{
                borderRadius: '50%',
                width: { xs: '40px', md: '56px' }, // Set the width to a fixed value
                height: { xs: '40px', md: '56px' }, // Set the height to the same fixed value
                minWidth: '40px', // Ensure the minimum width is the same
              }}
              onClick={() => setProfessionalsFiltersDialogOpen(true)}
            >
              <TuneRounded />
            </Button>
          </Tooltip>
        </div>
      </div>
    )
  );
};

export default ProfessionalsPage;

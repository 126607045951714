import { useEffect, useState } from 'react';
import LoginDrawer from '../Drawers/LoginDrawer';
import MenuDrawer from '../Drawers/MenuDrawer';
import { Link, useNavigate } from 'react-router-dom';

import logo from '../../media/logo-gjb-nobg.png';
import { MenuOutlined, PersonOutlineOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { drawerValuesActions } from '../../store/drawerValues';

const NavBar = () => {
  const [state, setState] = useState(false);
  const [menuDrawer, setMenuDrawer] = useState(false);
  const [loginDrawer, setLoginDrawer] = useState(false);
  const { userId } = useSelector((state) => state.loginValues);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.onclick = (e) => {
      const target = e.target;
      if (!target.closest('.menu-btn')) setState(false);
    };
  }, []);

  return (
    <nav className='relative flex items-center justify-between border-b border-green-700 p-4 bg-green-700'>
      <MenuDrawer />
      <LoginDrawer />
      <div className='flex justify-self-start'>
        <button
          onClick={() => dispatch(drawerValuesActions.setMenuDrawer(true))}
          className='px-3 py-1.5 text-sm border-transparent duration-100 border rounded-lg hover:bg-emerald-400'
        >
          <MenuOutlined />
        </button>
      </div>
      <div
        className='absolute left-1/2 transform -translate-x-1/2 hover:cursor-pointer'
        onClick={() => navigate('/')}
      >
        <img src={logo} alt='logo' className='w-12 h-12' />
      </div>
      <div className='flex justify-self-end'>
        <div className='hidden lg:flex '>
          <button
            onClick={() => dispatch(drawerValuesActions.setLoginDrawer(true))}
            className='font-semibold text-xl flex items-center gap-2 px-3 py-1.5 border-transparent text-black duration-100  rounded-lg hover:bg-emerald-400 '
          >
            <PersonOutlineOutlined />
            {userId ? 'Minha conta' : 'Entrar'}
          </button>
        </div>
        <div className='lg:hidden flex items-center'>
          <button
            onClick={() => dispatch(drawerValuesActions.setLoginDrawer(true))}
            className='flex items-center gap-2 px-3 py-1.5 border-transparent text-sm text-black duration-100  rounded-lg hover:bg-emerald-400 '
          >
            <PersonOutlineOutlined />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;

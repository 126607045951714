import useSWR from 'swr';
import { serverUrl } from '../config/config';

// Create a get method to fetch the address
export const createInitialCompany = async (userData) => {
  try {
    const response = await fetch(`${serverUrl}/companies/initialCompany`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(userData),
    });
    const data = await response.json();
    console.log('response: ', data);
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const createInitialProfessional = async (userData) => {
  try {
    const response = await fetch(
      `${serverUrl}/professionals/initialProfessional`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(userData),
      }
    );
    const text = await response.text();
    console.log('response: ', text);
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getInitialProfile = async (token) => {
  try {
    const response = await fetch(`${serverUrl}/users/initialProfile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    console.log('response: ', data);
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const useInitialProfile = () => {
  const { data, error, isLoading } = useSWR(
    `initialProfile`,
    getInitialProfile
  );

  return {
    initialProfile: data,
    isLoading,
    isError: error,
  };
};

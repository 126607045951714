import React from 'react';
import logo from '../../media/logo-gjb-nobg.png';

const CompanyCard = ({ company, handleOpenFullCompanyCardDialog }) => {
  return (
    <div
      key={company._id}
      className='flex border-black border-2 rounded-md shadow-lg w-fit p-4 cursor-pointer hover:scale-101 ease-in-out duration-100 bg-slate-100'
      onClick={() => handleOpenFullCompanyCardDialog(company)}
    >
      <div className='w-32 h-32'>
        <img src={logo} alt='' />
      </div>
      <div className='flex flex-col gap-2 justify-center'>
        <h1 className='text-xl font-bold text-green-700'>
          {`${company.companyName}`}
        </h1>
        <p className='text-lg font-bold'>{company.companyFieldOfActivity[0]}</p>
        <p className='text-md'>{`${company.companyCity} - ${company.companyState}`}</p>
      </div>
    </div>
  );
};

export default CompanyCard;

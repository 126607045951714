import React, { useState } from 'react';
import {
  Avatar,
  Button,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';

import logo from '../../media/logo-gjb-nobg.png';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import {
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteOutlined,
  HomeRepairServiceOutlined,
  HouseOutlined,
  LocalShippingOutlined,
  Logout,
  NotificationsNone,
  PhotoCameraOutlined,
  SettingsOutlined,
  StarRateOutlined,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { drawerValuesActions } from '../../store/drawerValues';
import { auth } from '../../firebase-config';
import { deepOrange } from '@mui/material/colors';
import { toast } from 'react-toastify';

const MyAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userName } = useSelector((state) => state.loginValues);
  const handleLogout = () => {
    auth.signOut().then(() => {
      toast.success('Deslogado com sucesso');
      // navigate('/');
    });
  };
  const handleNavigation = (path) => {
    dispatch(drawerValuesActions.setLoginDrawer(false));
    navigate(path);
  };
  return (
    <div className='flex flex-col h-full '>
      <div className='flex px-6 py-4 gap-4 items-center'>
        <div className=''>
          <Avatar sx={{ bgcolor: deepOrange[500] }}>
            {userName?.charAt(0) || 'U'}
          </Avatar>
        </div>
        <div>
          <h1 className='text-xl font-bold'>
            {userName && userName.split(' ')[0]}
          </h1>
          <Link
            className='text-emerald-800 cursor-pointer text-sm font-semibold hover:underline'
            to='/minha-conta'
            onClick={() => handleNavigation('/minha-conta')}
          >
            Ver meu perfil
          </Link>
        </div>
      </div>
      <div className='grow'>
        <div>
          <Divider my='3' size='4' />
        </div>
        {/* <List>
          <ListItem onClick={() => handleNavigation('/menu')}>
            <ListItemButton>
              <ListItemIcon>
                <img width={26} src={logo} />
              </ListItemIcon>
              <ListItemText primary='Menu 1' />
            </ListItemButton>
          </ListItem>
          <ListItem onClick={() => handleNavigation('/menu')}>
            <ListItemButton>
              <ListItemIcon>
                <img width={26} src={logo} />
              </ListItemIcon>
              <ListItemText primary='Menu 2' />
            </ListItemButton>
          </ListItem>
          <ListItem onClick={() => handleNavigation('/menu')}>
            <ListItemButton>
              <ListItemIcon>
                <img width={26} src={logo} />
              </ListItemIcon>
              <ListItemText primary='Menu 3' />
            </ListItemButton>
          </ListItem>
          <ListItem onClick={() => handleNavigation('/menu')}>
            <ListItemButton>
              <ListItemIcon>
                <img width={26} src={logo} />
              </ListItemIcon>
              <ListItemText primary='Menu 4' />
            </ListItemButton>
          </ListItem>
        </List> */}
      </div>
      <div className=''>
        <div>
          <Divider my='3' size='4' />
        </div>
        <List>
          {/* <ListItem onClick={() => handleNavigation('/configuracoes')}>
            <ListItemButton>
              <ListItemIcon>
                <SettingsOutlined />
              </ListItemIcon>
              <p className=''>Configurações</p>
            </ListItemButton>
          </ListItem> */}
          <ListItem onClick={handleLogout}>
            <ListItemButton>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>

              <p className=''>Sair</p>
            </ListItemButton>
          </ListItem>
        </List>
      </div>
    </div>
  );
};

export default MyAccount;

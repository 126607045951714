import React from 'react';

import { Button, Dialog, DialogTitle } from '@mui/material';
import {
  graduationYear,
  professionalFormFields,
  states,
} from '../../helper/profileFormFields';
import { industryList } from '../../helper/industry';

const CompaniesFiltersDialog = ({
  companiesFiltersDialogOpen,
  handleFiltersDialogClose,
  handleSubmit,
  onSubmit,
  register,
}) => {
  return (
    <Dialog
      open={companiesFiltersDialogOpen}
      onClose={handleFiltersDialogClose}
      fullWidth
    >
      <DialogTitle alignSelf={'center'} fontSize={'24px'} color={'green'}>
        Filtros
      </DialogTitle>
      <div className='flex flex-col gap-6 p-6 w-full items-center'>
        <div className='flex flex-col gap-2 w-full xs:w-3/5 sm:w-3/5 md:w-2/5'>
          <label className='w-32 text-green-700 font-medium'>Cidade</label>
          <input
            {...register('companyCity')}
            type='text'
            placeholder='Digite a cidade'
            id='companyCity'
            className='w-full h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-green-500'
          ></input>
        </div>
        <div className='flex flex-col gap-2 w-full xs:w-3/5 sm:w-3/5 md:w-2/5'>
          <label className='w-32 text-green-700 font-medium'>Estado</label>
          <select
            {...register('companyState')}
            id='companyState'
            className='w-full h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-green-500'
          >
            <option value=''></option>
            {states.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>

        <div className='flex flex-col gap-2 w-full xs:w-3/5 sm:w-3/5 md:w-2/5'>
          <label className='text-green-700 font-medium'>Setor</label>
          <select
            {...register('industry')}
            id='industry'
            className='w-full h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-green-500'
          >
            <option value=''></option>
            {industryList.map((industry) => (
              <option key={industry} value={industry}>
                {industry}
              </option>
            ))}
          </select>
        </div>
        <div className='flex flex-col gap-2 w-full xs:w-3/5 sm:w-3/5 md:w-2/5'>
          <label className='text-green-700 font-medium'>Especialidade</label>
          <select
            {...register('specialty')}
            id='specialty'
            className='w-full h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-green-500'
          >
            <option value=''></option>
            {professionalFormFields.specialty.map((specialty) => (
              <option key={specialty} value={specialty}>
                {specialty}
              </option>
            ))}
          </select>
        </div>
        <div className='p-4'>
          <Button
            variant='contained'
            color='success'
            onClick={handleSubmit(onSubmit)}
          >
            Buscar
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default CompaniesFiltersDialog;

import React from 'react';
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import { ArrowBack, CloseOutlined, CloseRounded } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import FullProfessionalCard from '../Cards/FullProfessionalCard';
import FullCompanyCard from '../Cards/FullCompanyCard';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' timeout={3000} ref={ref} {...props} />;
});

const FullCompanyCardDialog = ({
  handleCloseFullCompanyCardDialog,
  companyDialogOpen,
}) => {
  const { selectedCompany } = useSelector((state) => state.dialogValues);

  return (
    <Dialog
      fullScreen
      open={companyDialogOpen}
      onClose={handleCloseFullCompanyCardDialog}
      TransitionComponent={Transition}
      maxWidth='xl'
      scroll='paper'
    >
      <AppBar color='success'>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            Detalhes
          </Typography>
          <IconButton onClick={handleCloseFullCompanyCardDialog}>
            <ArrowBack sx={{ color: 'white' }} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <FullCompanyCard
        handleCloseFullCompanyCardDialog={handleCloseFullCompanyCardDialog}
      />
    </Dialog>
  );
};

export default FullCompanyCardDialog;

import { createSlice } from '@reduxjs/toolkit';
import { set } from 'react-hook-form';

const initialFormState = {
  selectedProfessional: null,
  selectedCompany: null,
};

const dialogValuesSlice = createSlice({
  name: 'dialogValues',
  initialState: initialFormState,
  reducers: {
    setSelectedProfessional(state, action) {
      state.selectedProfessional = action.payload;
    },
    setSelectedCompany(state, action) {
      state.selectedCompany = action.payload;
    },
    resetSelectedProfessional(state) {
      state.selectedProfessional = null;
    },
    resetSelectedCompany(state) {
      state.selectedCompany = null;
    },
  },
});

export const dialogValuesActions = dialogValuesSlice.actions;

export default dialogValuesSlice.reducer;

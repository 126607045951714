import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const HomePageCards = ({ img, text, link, status }) => {
  const navigate = useNavigate();
  return (
    <div
      className='w-4/5 h-96 sm:w-2/5 lg:w-96 shadow-lg relative hover:scale-102 hover:cursor-pointer duration-500 rounded-lg border-zinc-800 border-2'
      onClick={() => navigate(link)}
    >
      <div className='absolute inset-0 bg-black opacity-65 rounded-md'></div>
      <h1 className='absolute left-1/2 top-36 transform -translate-x-2/4 sm:text-3xl lg:text-4xl text-2xl text-slate-200 font-bold text-center'>
        {text}
      </h1>
      <img
        src={img}
        alt='capa'
        className='object-cover w-full h-full rounded-lg'
      />
      <Button
        variant='contained'
        color='success'
        className='absolute left-1/2 transform -translate-x-1/2 bottom-12'
        // onClick={() => navigate(link)}
      >
        {!status ? 'Saiba mais' : 'Em breve'}
      </Button>
    </div>
  );
};

export default HomePageCards;

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { drawerValuesActions } from '../../store/drawerValues';
import { Button } from '@mui/material';
import profissionaisOfflineImg from '../../media/profissionais-offline.jpg';
import profissionaisOfflineImg2 from '../../media/profissionais-offline2.jpg';
import profissionaisOfflineImg3 from '../../media/profissionais-offline3.jpg';
import profissionaisOfflineImg4 from '../../media/profissionais-offline4.jpeg';

const CompaniesNotLogged = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useDispatch();
  const handleLoginDrawer = () => {
    dispatch(drawerValuesActions.setLoginDrawer(true));
  };

  const carouselItems = [
    {
      imgSrc: profissionaisOfflineImg,
      text: 'Encontre empresas do setor ambiental, sustentabilidade e ESG',
    },
    {
      imgSrc: profissionaisOfflineImg2,
      text: 'Empresas especializadas em biodiversidade, recursos hidricos e energias renováveis',
    },
    {
      imgSrc: profissionaisOfflineImg3,
      text: 'Filtre por setor, especialização e localização geográfica',
    },
    {
      imgSrc: profissionaisOfflineImg4,
      text: 'Encontrou a empresa que procura? Entre em contato direto e solicite um orçamento',
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [carouselItems.length]);

  return (
    <div className='flex-grow flex flex-col items-center relative overflow-hidden'>
      <div className=' absolute inset-0'>
        {carouselItems.map((item, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-1000 ${
              index === currentIndex ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <div className='absolute inset-0 bg-black opacity-45'></div>
            <img
              src={item.imgSrc}
              alt='capa'
              className='object-cover w-full h-full'
            />
            <h1 className='absolute left-1/2 top-12 transform -translate-x-1/2 sm:text-3xl lg:text-4xl xl:text-5xl text-2xl text-slate-100 font-bold text-center'>
              {item.text}
            </h1>
          </div>
        ))}

        <div className='w-44 h-14 absolute left-1/2 top-3/4 transform -translate-x-1/2 md:top-1/2 md:transform md:-translate-y-1/2'>
          <Button
            className='w-full h-full'
            variant='contained'
            color='success'
            size='large'
            onClick={handleLoginDrawer}
          >
            Entrar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompaniesNotLogged;

import React from 'react';
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import { ArrowBack, CloseOutlined, CloseRounded } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import FullProfessionalCard from '../Cards/FullProfessionalCard';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' timeout={3000} ref={ref} {...props} />;
});

const FullProfessionalCardDialog = ({
  handleCloseFullProfessionalCardDialog,
  professionalDialogOpen,
}) => {
  const { selectedProfessional } = useSelector((state) => state.dialogValues);

  return (
    <Dialog
      fullScreen
      open={professionalDialogOpen}
      onClose={handleCloseFullProfessionalCardDialog}
      TransitionComponent={Transition}
      maxWidth='xl'
      scroll='paper'
    >
      <AppBar color='success'>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            {/* {`${selectedProfessional.name} ${selectedProfessional.lastName}`} */}
            Detalhes
          </Typography>
          <IconButton onClick={handleCloseFullProfessionalCardDialog}>
            <ArrowBack sx={{ color: 'white' }} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <FullProfessionalCard
        handleCloseFullProfessionalCardDialog={
          handleCloseFullProfessionalCardDialog
        }
      />
    </Dialog>
  );
};

export default FullProfessionalCardDialog;

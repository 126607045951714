import React, { useEffect, useState } from 'react';

import capa from '../media/logo-gjb-bg.png';
import profissionaisImg from '../media/profissionais-card.jpg';
import empresasImg from '../media/empresas-card.jpg';
import noticiasImg from '../media/noticias-card.jpg';
import listaOdsImg from '../media/lista_ods.jpg';
import video1 from '../media/video1.mp4';
import video2 from '../media/video2.mp4';
import video3 from '../media/video3.mp4';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HomePageCards from '../components/Layout/HomePageCards';

const HomePage = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const carouselItems = [
    {
      videoSrc: video1,
      text: 'Bem-vindo à Green Jobs Brasil',
    },
    {
      videoSrc: video2,
      text: 'O ponto de partida dos negócios verdes',
    },
    {
      videoSrc: video3,
      text: 'Somos uma plataforma de conexão à empresas e profissionais ambientais, sustentabilidade e ESG',
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [carouselItems.length]);

  return (
    <div className='flex flex-col items-center justify-center gap-8 pb-40'>
      <div className='relative w-full h-96'>
        {carouselItems.map((item, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-1000 ${
              index === currentIndex ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <div className='absolute inset-0 bg-black opacity-45'></div>
            <video
              src={item.videoSrc}
              className='object-cover w-full h-full'
              autoPlay
              loop
              muted
            />
            <h1 className='absolute left-1/2 top-12 transform -translate-x-1/2 sm:text-3xl lg:text-4xl xl:text-5xl text-2xl text-slate-100 font-bold text-center'>
              {item.text}
            </h1>
          </div>
        ))}
      </div>
      <h1 className='text-2xl lg:text-5xl my-10 font-bold'>
        Aqui você encontra
      </h1>
      <div className='flex items-center justify-center gap-4 flex-wrap'>
        <HomePageCards
          img={profissionaisImg}
          text={'Encontre profissionais capacitados'}
          link={'/profissionais'}
        />
        <HomePageCards
          img={empresasImg}
          text={'Empresas de todas as áreas'}
          link={'/empresas'}
        />
        <HomePageCards
          img={noticiasImg}
          text={'Novidades do mercado'}
          link={'/'}
          status={'em breve'}
        />
        <HomePageCards
          img={listaOdsImg}
          text={'Soluções ODS'}
          link={'/'}
          status={'em breve'}
        />
      </div>
    </div>
  );
};

export default HomePage;

import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';

import { auth } from '../../firebase-config';
import { useForm } from 'react-hook-form';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { toast } from 'react-toastify';

const LoginForm = ({ login, setLogin }) => {
  const handleLogin = ({ email, password }) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log('User: ', user);
        toast.success('Login efetuado com sucesso!');

        // ...
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/invalid-credential':
            toast.error('Email inválido');
            break;
          case 'auth/user-not-found':
            toast.error('Usuário não encontrado');
            break;
          case 'auth/wrong-password':
            toast.error('Senha incorreta');
            break;
          default:
            break;
        }
        // console.log('Error: ', error.code);
      });
  };

  const {
    formState,
    register,

    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    resetField,
  } = useForm({});

  return (
    <div>
      <form onSubmit={handleSubmit(handleLogin)}>
        <div className='flex flex-col p-4 gap-8 mt-6'>
          <div className='flex flex-col gap-4 flex-wrap'>
            <Typography variant='h6' fontSize={16} fontWeight='bold'>
              Faça login para continuar
            </Typography>
            <TextField
              {...register('email', { required: true })}
              id='email-button'
              label='Email'
              placeholder='Digite seu email'
              type='email'
            />
            <TextField
              {...register('password', { required: true })}
              id='password-button'
              label='Senha'
              placeholder='Digite sua senha'
              type='password'
            />
            <p
              className='hover:text-emerald-800 hover:underline hover:cursor-pointer w-fit text-red-500'
              onClick={() => setLogin(false)}
            >
              Esqueci minha senha
            </p>
            <p
              className='hover:text-emerald-800 hover:underline hover:cursor-pointer w-fit'
              onClick={() => setLogin(false)}
            >
              Não possui conta? Registre-se
            </p>
          </div>
          <Button
            className=''
            variant='contained'
            color='success'
            size='large'
            type='submit'
            // onClick={onSubmit(handleLogin)}
          >
            ENTRAR
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;

import { serverUrl } from '../config/config';

// Create a get method to complete the company profile
export const completeCompany = async (companyData) => {
  try {
    const response = await fetch(`${serverUrl}/companies`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(companyData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

// Create a method to get the companies list based on the filters
export const getCompanies = async (filters) => {
  try {
    const response = await fetch(`${serverUrl}/companies/filtrar`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(filters),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

import React, { useEffect } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { get, useForm } from 'react-hook-form';
import {
  graduationYear,
  professionalFormFields,
  states,
} from '../helper/profileFormFields';
import { toast } from 'react-toastify';
import { completeProfessional } from '../services/professionalServices';
import { useNavigate } from 'react-router-dom';
import NewProfessionalForm from '../components/Forms/NewProfessionalForm';
import { useDispatch, useSelector } from 'react-redux';
import NewCompanyForm from '../components/Forms/NewCompanyForm';
import { completeCompany } from '../services/companyServices';
import { loginValuesActions } from '../store/loginValues';
import EditProfessionalForm from '../components/Forms/EditProfessionalForm';
import EditCompanyForm from '../components/Forms/EditCompanyForm';
import { drawerValuesActions } from '../store/drawerValues';

const EditProfileFormPage = () => {
  const navigate = useNavigate();
  const { userData, userId } = useSelector((state) => state.loginValues);
  const dispatch = useDispatch();
  const handleRegister = (profileData) => {
    console.log('profileData', profileData);
    if (userData.profile === 'company') {
      completeCompany(profileData).then((response) => {
        if (response) {
          toast.success('Empresa editada com sucesso.');
          console.log('Empresa editada com sucesso.', response);
          dispatch(loginValuesActions.setUserData(response));
          navigate('/');
        } else {
          toast.error('Erro ao criar empresa.');
        }
      });
    }
    if (userData.profile === 'professional') {
      completeProfessional(profileData).then((response) => {
        if (response) {
          toast.success('Profissional editado com sucesso.');
          console.log('Profissional editado com sucesso.', response);
          dispatch(loginValuesActions.setUserData(response));
          navigate('/');
        } else {
          toast.error('Erro ao criar profissional.');
        }
      });
    }
  };

  const handleFileChange = (event) => {
    console.log(event.target.files[0]);
    const file = event.target.files[0];
    if (!file) {
      toast.error('Nenhuma imagem foi selecionada.');
    }

    const validExtensions = ['image/jpeg', 'image/png'];
    if (!validExtensions.includes(file.type)) {
      toast.error('A imagem deve ser no formato PNG ou JPG.');
      return;
    }
    setValue('companyLogo', file);
    console.log(getValues('companyLogo'));
  };

  const {
    formState,
    register,

    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    resetField,
  } = useForm({
    defaultValues: {
      companyLogo: null,
      companyName: userData.companyName,
      companyDescription: userData.companyDescription,
      companyPhone: userData.companyPhone,
      companyEmail: userData.companyEmail,
      companyWebsite: userData.companyWebsite,
      companyState: userData.companyState,
      companyCity: userData.companyCity,
      companyFieldOfActivity: userData.companyFieldOfActivity,
    },
  });

  useEffect(() => {
    reset({
      companyLogo: null,
      companyName: userData.companyName,
      companyDescription: userData.companyDescription,
      companyPhone: userData.companyPhone,
      companyEmail: userData.companyEmail,
      companyWebsite: userData.companyWebsite,
      companyState: userData.companyState,
      companyCity: userData.companyCity,
      companyFieldOfActivity: userData.companyFieldOfActivity,
      companySpecialty: userData.companySpecialty,
    });
    setValue('companyName', userData.companyName);
    setValue('companyDescription', userData.companyDescription);
    setValue('companyPhone', userData.companyPhone);
    setValue('companyEmail', userData.companyEmail);
    setValue('companyWebsite', userData.companyWebsite);
    setValue('companyState', userData.companyState);
    setValue('companyCity', userData.companyCity);
    setValue('companyFieldOfActivity', userData.companyFieldOfActivity);
    setValue('companySpecialty', userData.companySpecialty);

    console.log(getValues());
  }, [userData]);

  if (!userData) {
    return (
      <div className='flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4'>
        <div className='flex flex-col items-center bg-white shadow-md rounded-lg p-6 w-full max-w-md'>
          <h1 className='text-2xl font-bold text-black mb-4'>
            Faça login para continuar
          </h1>
          <button
            onClick={() => dispatch(drawerValuesActions.setLoginDrawer(true))}
            className='bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-800'
          >
            Fazer Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className='flex flex-col text-center text-4xl  font-bold items-center mt-10 gap-8'>
        <h1>Complete seu perfil</h1>
        {userData && userData.profile === 'professional' ? (
          <EditProfessionalForm
            register={register}
            handleFileChange={handleFileChange}
            handleRegister={handleRegister}
            handleSubmit={handleSubmit}
            watch={watch}
            getValues={getValues}
            setValue={setValue}
          />
        ) : (
          <EditCompanyForm
            register={register}
            handleFileChange={handleFileChange}
            handleRegister={handleRegister}
            handleSubmit={handleSubmit}
            watch={watch}
            getValues={getValues}
            setValue={setValue}
          />
        )}
      </div>
    </div>
  );
};

export default EditProfileFormPage;

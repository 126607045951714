import React, { useEffect } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  graduationYear,
  professionalFormFields,
  states,
} from '../helper/profileFormFields';
import { toast } from 'react-toastify';
import { completeProfessional } from '../services/professionalServices';
import { useNavigate } from 'react-router-dom';
import NewProfessionalForm from '../components/Forms/NewProfessionalForm';
import { useDispatch, useSelector } from 'react-redux';
import NewCompanyForm from '../components/Forms/NewCompanyForm';
import { completeCompany } from '../services/companyServices';
import { loginValuesActions } from '../store/loginValues';

const ProfileFormPage = () => {
  const navigate = useNavigate();
  const { userData, userId } = useSelector((state) => state.loginValues);
  const dispatch = useDispatch();
  const handleRegister = (profileData) => {
    console.log('profileData', profileData);
    if (userData.profile === 'company') {
      completeCompany(profileData).then((response) => {
        if (response) {
          toast.success('Empresa criada com sucesso.');
          console.log('Empresa criada com sucesso.', response);
          dispatch(loginValuesActions.setUserData(response));
          navigate('/');
        } else {
          toast.error('Erro ao criar empresa.');
        }
      });
    }
    if (userData.profile === 'professional') {
      completeProfessional(profileData).then((response) => {
        if (response) {
          toast.success('Profissional criado com sucesso.');
          console.log('Profissional criado com sucesso.', response);
          dispatch(loginValuesActions.setUserData(response));
          navigate('/');
        } else {
          toast.error('Erro ao criar profissional.');
        }
      });
    }
  };

  const handleFileChange = (event) => {
    console.log(event.target.files[0]);
    const file = event.target.files[0];
    if (!file) {
      toast.error('Nenhuma imagem foi selecionada.');
    }

    const validExtensions = ['image/jpeg', 'image/png'];
    if (!validExtensions.includes(file.type)) {
      toast.error('A imagem deve ser no formato PNG ou JPG.');
      return;
    }
    setValue('companyLogo', file);
    console.log(getValues('companyLogo'));
  };

  const {
    formState,
    register,

    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    resetField,
  } = useForm({
    defaultValues: {},
  });

  if (!userData || userData?.completedProfile === true) {
    return (
      <div className='flex flex-col items-center gap-4 mt-10'>
        <Typography variant='h4' color='primary'>
          Seu perfil já está completo!
        </Typography>
        <Button
          variant='contained'
          color='primary'
          onClick={() => navigate('/')}
        >
          Voltar para a página inicial
        </Button>
      </div>
    );
  }

  return (
    <div>
      <div className='flex flex-col text-center text-4xl  font-bold items-center mt-10 gap-8'>
        <h1>Complete seu perfil</h1>
        {userData && userData.profile === 'professional' ? (
          <NewProfessionalForm
            register={register}
            handleFileChange={handleFileChange}
            handleRegister={handleRegister}
            handleSubmit={handleSubmit}
            watch={watch}
            getValues={getValues}
            setValue={setValue}
          />
        ) : (
          <NewCompanyForm
            register={register}
            handleFileChange={handleFileChange}
            handleRegister={handleRegister}
            handleSubmit={handleSubmit}
            watch={watch}
            getValues={getValues}
            setValue={setValue}
          />
        )}
      </div>
    </div>
  );
};

export default ProfileFormPage;

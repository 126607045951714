import React, { useState } from 'react';
import {
  Button,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';

import logo from '../../media/logo-gjb-nobg.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { drawerValuesActions } from '../../store/drawerValues';
import { IconButton } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

const MenuDrawer = () => {
  const [open, setOpen] = useState(false);
  const { menuDrawerOpen } = useSelector((state) => state.drawerValues);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const chooseMenu = (menu) => {
    switch (menu) {
      case 'empresas':
        dispatch(drawerValuesActions.setMenuDrawer(false));
        setOpen(false);
        navigate('/empresas');
        break;
      case 'profissionais':
        dispatch(drawerValuesActions.setMenuDrawer(false));
        setOpen(false);
        navigate('/profissionais');
        break;
      case 'ods':
        dispatch(drawerValuesActions.setMenuDrawer(false));
        setOpen(false);
        navigate('/ods');
        break;
      case 'noticias':
        dispatch(drawerValuesActions.setMenuDrawer(false));
        setOpen(false);
        navigate('/noticias');
        break;
      default:
        break;
    }
  };
  return (
    <Drawer
      open={menuDrawerOpen}
      onClose={() => dispatch(drawerValuesActions.setMenuDrawer(false))}
      anchor='left'
    >
      <div className='w-80'>
        <div className='flex justify-between p-4'>
          <img src={logo} alt='logo' className='w-8 h-8' />
          <h1 className='font-semibold text-xl '>Green Jobs</h1>

          <IconButton
            className='hover:cursor-pointer'
            onClick={() => dispatch(drawerValuesActions.setMenuDrawer(false))}
          >
            <CloseOutlined />
          </IconButton>
        </div>
        <Divider my='3' size='4' />
        <List>
          <ListItem key='Empresas' onClick={() => chooseMenu('empresas')}>
            <ListItemButton>
              <ListItemIcon>
                <img width={25} src={logo} />
              </ListItemIcon>
              <ListItemText primary='Empresas' />
            </ListItemButton>
          </ListItem>
          <ListItem
            key='Profissionais'
            onClick={() => chooseMenu('profissionais')}
          >
            <ListItemButton>
              <ListItemIcon>
                <img width={25} src={logo} />
              </ListItemIcon>
              <ListItemText primary='Profissionais' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Ods' onClick={() => chooseMenu('ods')}>
            <ListItemButton>
              <ListItemIcon>
                <img width={25} src={logo} />
              </ListItemIcon>
              <ListItemText primary='Soluções ODS' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Notícias' onClick={() => chooseMenu('noticias')}>
            <ListItemButton>
              <ListItemIcon>
                <img width={25} src={logo} />
              </ListItemIcon>
              <ListItemText primary='Notícias' />
            </ListItemButton>
          </ListItem>

          {/* <ListItem
            key='Serviços'
            onClick={() => {
              setOpen((prev) => !prev);
              //   chooseMenu('comercial');
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <img width={25} src={logo} />
              </ListItemIcon>
              <ListItemText primary='Serviços' />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem> */}

          {/* <Collapse in={open} timeout='auto'>
            <List component='div' disablePadding>
              <ListItem
                sx={{ pl: 4 }}
                onClick={() => {
                  chooseMenu('3');
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <img width={25} src={logo} />
                  </ListItemIcon>
                  <ListItemText primary='Menu 3' />
                </ListItemButton>
              </ListItem>
              <ListItem
                sx={{ pl: 4 }}
                onClick={() => {
                  chooseMenu('4');
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <img width={25} src={logo} />
                  </ListItemIcon>
                  <ListItemText primary='Menu 4' />
                </ListItemButton>
              </ListItem>
              <ListItem
                sx={{ pl: 4 }}
                onClick={() => {
                  chooseMenu('5');
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <img width={25} src={logo} />
                  </ListItemIcon>
                  <ListItemText primary='Menu 5' />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse> */}
        </List>
      </div>
    </Drawer>
  );
};

export default MenuDrawer;

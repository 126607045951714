import { configureStore } from '@reduxjs/toolkit';

import drawerValuesReducer from './drawerValues';
import loginValuesReducer from './loginValues';
import dialogValuesReducer from './dialogValues';

const store = configureStore({
  reducer: {
    drawerValues: drawerValuesReducer,
    loginValues: loginValuesReducer,
    dialogValues: dialogValuesReducer,
  },
});

export default store;

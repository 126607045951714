import { useEffect, useState, React } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import './index.css';
import HomePage from './pages/HomePage';
import { onAuthStateChanged, updateProfile } from 'firebase/auth';
import { auth } from './firebase-config';
import { useDispatch } from 'react-redux';
import { loginValuesActions } from './store/loginValues';
import NavBar from './components/Layout/NavBar';
import { toast } from 'react-toastify';
import NotFoundPage from './pages/NotFoundPage';
import ProfileFormPage from './pages/ProfileFormPage';
import ProfessionalsPage from './pages/ProfessionalsPage';
import CompaniesPage from './pages/CompaniesPage';
import OdsPage from './pages/OdsPage';
import NewsPage from './pages/NewsPage';
import { getInitialProfile } from './services/userServices';
import ProfilePage from './pages/ProfilePage';
import EditProfileFormPage from './pages/EditProfileFormPage';

const PageWrapper = () => (
  <div className='flex flex-col min-h-screen'>
    <NavBar />
    <Outlet />
  </div>
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <PageWrapper />,
    children: [
      { index: true, element: <HomePage /> },
      { path: 'completar-perfil', element: <ProfileFormPage /> },
      { path: 'editar-perfil', element: <EditProfileFormPage /> },
      { path: 'minha-conta', element: <ProfilePage /> },
      { path: 'profissionais', element: <ProfessionalsPage /> },
      { path: 'empresas', element: <CompaniesPage /> },
      { path: 'ods', element: <OdsPage /> },
      { path: 'noticias', element: <NewsPage /> },
      { path: '*', element: <NotFoundPage /> },
    ],
  },
]);

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchAccountDetails = async (token) => {
      try {
        const response = await getInitialProfile(token);

        // Dispatch the account details to the Redux store
        dispatch(loginValuesActions.setUserData(response));
      } catch (error) {
        console.error('Error fetching account details:', error);
      }
    };
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('User is logged in', user);
        user.getIdToken(true).then((token) => {
          localStorage.setItem('token', token);
          fetchAccountDetails(token);
        });
        // localStorage.setItem('token', user.accessToken);
        dispatch(loginValuesActions.setUserId(user.uid));
        dispatch(loginValuesActions.setUserName(user.displayName));
        dispatch(loginValuesActions.setUserEmail(user.email));
        const createdAt = new Date(user.metadata.creationTime);
        const lastLogin = new Date(user.metadata.lastSignInTime);
        dispatch(
          loginValuesActions.setUserCreatedAt(
            new Intl.DateTimeFormat('pt-BR', {
              dateStyle: 'short',
              timeStyle: 'short',
            }).format(createdAt)
          )
        );
        dispatch(
          loginValuesActions.setUserLastLogin(
            new Intl.DateTimeFormat('pt-BR', {
              dateStyle: 'short',
              timeStyle: 'short',
            }).format(lastLogin)
          )
        );
      } else {
        // console.log('User is not logged in');
        localStorage.clear();
        dispatch(loginValuesActions.resetLoginValues());
        // toast.error('Faça login para continuar');
      }
      return unsubscribe;
    });
  }, []);
  return <RouterProvider router={router} />;
};

export default App;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { drawerValuesActions } from '../store/drawerValues';

const ProfilePage = () => {
  const { userData } = useSelector((state) => state.loginValues);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEditProfile = () => {
    navigate('/editar-perfil');
  };

  if (!userData) {
    return (
      <div className='flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4'>
        <div className='flex flex-col items-center bg-white shadow-md rounded-lg p-6 w-full max-w-md'>
          <h1 className='text-2xl font-bold text-black mb-4'>
            Faça login para continuar
          </h1>
          <button
            onClick={() => dispatch(drawerValuesActions.setLoginDrawer(true))}
            className='bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-800'
          >
            Fazer Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4'>
      <div className='bg-white shadow-md rounded-lg p-6 w-full max-w-md'>
        <h1 className='text-2xl font-bold text-green-700 mb-4'>Minha conta</h1>
        <div className='mb-4'>
          <label className='block text-gray-700 font-bold mb-2'>Nome:</label>
          <p className='text-gray-900'>{`${userData.name} ${userData.lastName}`}</p>
        </div>
        <div className='mb-4'>
          <label className='block text-gray-700 font-bold mb-2'>Email:</label>
          <p className='text-gray-900'>{userData.userEmail}</p>
        </div>
        <div className='mb-4'>
          <label className='block text-gray-700 font-bold mb-2'>Cidade:</label>
          <p className='text-gray-900'>{userData.companyCity}</p>
        </div>
        <div className='mb-4'>
          <label className='block text-gray-700 font-bold mb-2'>Estado:</label>
          <p className='text-gray-900'>{userData.companyState}</p>
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700 font-bold mb-2'>
            Criado em:
          </label>
          <p className='text-gray-900'>
            {new Intl.DateTimeFormat('pt-BR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }).format(new Date(userData.dateCreate))}
          </p>
        </div>
        <div className='mb-4'>
          <label className='block text-gray-700 font-bold mb-2'>
            Atualizado em:
          </label>
          <p className='text-gray-900'>
            {new Intl.DateTimeFormat('pt-BR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }).format(new Date(userData.dateUpdate))}
          </p>
        </div>

        <button
          onClick={handleEditProfile}
          className='bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-800'
        >
          Editar Dados
        </button>
      </div>
    </div>
  );
};

export default ProfilePage;

export const industryList = [
  'Consultoria Ambiental e Sustentabilidade',
  'Gestão de Resíduos',
  'Conservação e Restauração Ambiental',
  'Gestão de Recursos Naturais',
  'Educação e Sensibilização Ambiental',
  'Monitoramento e Tecnologia Ambiental',
  'Energias Renováveis e Eficiência Energética',
  'Gestão de Carbono e Mudanças Climáticas',
  'Auditorias e Licenciamento Ambiental',
  'Mercado de Carbono e Sustentabilidade Corporativa',
  'Justiça Climática e Direito Ambiental',
  'Gestão e Consultoria de Recursos Hídricos',
  'Arquitetura e Construção Sustentável',
];
